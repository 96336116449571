$jet: #2eb3a9

.contentWrapper
  flex-direction: column
  background: transparent
  min-height: 280px
  width: 100%
  padding-left: 250px
  
.antLayoutWrapper
  display: flex
  background: #FFFFFF
  height: 100%
  \:global
    .ant-menu-title-content
      color: $jet
    .ant-menu-submenu-active::after
      display: none

.logo
  float: left
  display: flex
  align-items: center
  height: 64px
  img
    height: 50px


.header
  position: fixed
  z-index: 1
  width: 100%
  background-color: transparent
  box-shadow: none
